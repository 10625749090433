import React, { useState } from 'react';
import "./tab.css"
const DefaultTab = ({ tabs, dir = 'col',dirTop='row' ,className='',cl='', widthClass='' }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.name);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className={`flex flex-col md:flex-${dirTop} w-full ${className} gap-4 `}>
    {/* Tab buttons container */}
    <div className={`flex flex-nowrap overflow-x-auto hide-scrollbar justify-start md:justify-center  items-center gap-2 flex-${dir} rounded-lg w-full ${cl} ${dirTop === 'row' ? 'w-full md:w-4/12' : 'w-full'}`}>
  {tabs.map((tab) => (
    <button
      key={tab?.name}
      className={`py-4 px-4 text-xl font-bold  ${widthClass} justify-center items-center transition-colors duration-200 shadow-md flex gap-2 bg-custom flex-shrink-0 min-w-[150px] min-h-[50px] ${activeTab === tab.name ? 'border-2 border-[#3474F6] font-bold text-[#3474F6]' : 'text-[#1D1929CC]'}`}
      onClick={() => handleTabClick(tab?.name)}
    >
      {tab.name}
    </button>
  ))}
</div>

  
    {/* Tab content container */}
    <div className={`flex overflow-x-auto hide-scrollbar gap-4 flex-row md:flex-${dir} justify-center items-center w-full ${cl} ${dirTop === 'row' ? 'md:w-8/12' : 'md:w-full bg-customWhite flex '}`}>
      {tabs.map((tab) => (
        <div
          key={tab?.name}
          className={`transition-opacity w-full duration-300 ${activeTab === tab.name ? 'block' : 'hidden'}`}
        >
          {tab?.content}
        </div>
      ))}
    </div>
  </div>
  
  );
};

export default DefaultTab;
