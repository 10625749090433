
import classroom from "../assets/images/classroom.png";
import wedding from "../assets/images/wedding.png";
import krypto from "../assets/images/krypto.png";
import moviex from "../assets/images/moviex.png";
import movieRecommender from "../assets/images/movierecommender.png";
import memory from "../assets/images/memoryChecker.png";
import hr from "../assets/images/hr.png";
import little from "../assets/images/little.png";
import stackkroo from "../assets/images/image.png";
import snake from "../assets/images/snake.png";
import tic from "../assets/images/tic.png";
import acco from "../assets/images/accor.png";
import file from "../assets/images/file.png";
import fyle from "../assets/images/fyle.png";
import portfolio from "../assets/images/portfolio.png";
import eduzone from "../assets/videos/eduZone.mp4";
import bombayhouseluxury from "../assets/images/bombay.png"
export const projects = [
  {
 id:0,
 title:"Bombay Luxury House",
 description:'Ecommerce Website with google login and admin portal',
 image:bombayhouseluxury,
 tags:['React','Redux','Tailwind','Node js','Mongo Db','Express Js'],
category: "web app",
 webapp:'https://bombayhouse.netlify.app'
  },
    {
      id: 0,
      title: "LittleBox",
      // date: "Jan 2024 - Dec 2023",
      description:" An encrypted, dynamic, responsive e-commerce website. It features a user's portal in addition to an admin portal.  Administrators utilise a different sign-up login.",
     image:little,
    //     ,
      tags: [
        "Html" , "Css"  , "Javascript" , "Bootstrap" , "Angular ", "Node js" , "Mongo db" ,"Express js" , "Typescript"
      ],
      category: "web app",
      // github: "
      // webapp: "https://decisionhub.netlify.app/",
    },
    {
      id: 1,
      title: "HR Management System",
      // date: "Jun 2023 - Jul 2023",
       description:" It is a robust platform designed to streamline and enhance the management of employee activities within an organization. This system caters to both employees and HR professionals, providing distinct features and functionalities for each user group to ensure efficient and effective administration and engagement.",
         image:hr,
     tags: [
      "React" ,"Redux" ,"Cloudinary" ,"Javascript",
      "Tailwind" ,"Multer" ,"NodeJs","ExpressJs","MongoDb"
      ],
      category: "web app",
       github: "https://github.com/ashima-27/frontendhr.git",
       webapp: "https://hrmanagement2728.netlify.app",
    },
    {
      id: 2,
      title: "Clone of Stackkaroo",
      // date: "Jun 2023 - Jul 2023",
       description:"A modern, high-performance web application built with the powerful Next.js framework. Our site leverages the benefits of client-side rendering to deliver a fast, responsive, and SEO-friendly experience. Whether you're browsing on a desktop, tablet, or mobile device, our site ensures a seamless and engaging user experience. Designed with scalability and flexibility in mind, our Next.js website provides a robust foundation for dynamic content, interactive features, and a superior web experience. Explore our site to see the future of web development in action. ",
          image:stackkroo,
     tags: [
      "Html" ,"CSS" ,"NextJs"
      ],
      category: "web app",
       github: "https://github.com/ashima-27/stackkroo.git",
       webapp: "https://stackkaroo.netlify.app",
    },
    {
      id: 3,
      title: "ETH-BLOCKY-KRYPTO",
      // date: "Jun 2023 - Jul 2023",
      description:" It is web 3.0 which connect the UI to the metamask account.  With the UI functionality we can perform different functionality.   Features: connect , disconnect , show all    previous transactions ,  login ,  log out , convert one  cryptocurrency to other , Shows all cryptocurrency chart ",
      image:krypto,
     tags: [
      "React" ,"Redux" ,"Solidity" ,"Javascript",
      "Tailwind" ,"Metamask"
      ],
      category: "web app",
       github: "https://github.com/ashima-27/ethblockcrypto.git",
       webapp: "https://6649c7a35b0c79998b326562--sprightly-heliotrope-76ec14.netlify.app/",
    },
    {
      id: 4,
      title: "Snake And Ladder",
      // date: "Jun 2023 - Jul 2023",
      description:"Snakes and Ladders is a classic board game where players roll dice to advance through numbered squares, avoiding snakes that send them backward and aiming for ladders that boost their progress toward the finish.",
         image:snake,
     tags: [
      "React" ,"Tailwind"
      ],
      category: "web app",
       github: "https://github.com/ashima-27/snakeandladder.git",
       webapp: "https://snakeandladder2728.netlify.app/",
    },
    {
      id: 5,
      title: "Tic Tac Toe",
      // date: "Jun 2023 - Jul 2023",
      description:"A dynamic Tic Tac Toe React app allowing users to define the board size",
       image:tic,
     tags: [
      "React" ,"Tailwind"
      ],
      category: "web app",
       github: "https://github.com/ashima-27/tictactoe.git",
       webapp: "https://tictactoe2728.netlify.app/",
    },
    {
      id: 6,
      title: "File Explorer",
      // date: "Jun 2023 - Jul 2023",
      description:"The file explorer app allows users to navigate through a hierarchical folder structure, create new files or folders, edit existing items, and delete them. It supports handling multiple actions like file creation, deletion, and renaming efficiently through a user-friendly interface.",
       image:file,
     tags: [
      "React" ,"CSS"
      ],
      category: "web app",
       github: "https://github.com/ashima-27/fileexplorer.git",
       webapp: "https://FileExplorer2728.netlify.app/",
    },
    {
      id: 7,
      title: "Accordion With Checkboxes",
      // date: "Jun 2023 - Jul 2023",
      description:"The accordion component integrates collapsible sections with checkboxes, facilitating interactive content management. It's ideal for organizing and selecting items efficiently in applications like file managers or settings panels.",
       image:acco,
     tags: [
      "React" ,"CSS"
      ],
      category: "web app",
       github: "https://github.com/ashima-27/React-Components.git",
       webapp: "https://accordionwithcheckbox.netlify.app/",
    },
    {
      id: 8,
      title: "NETFLIX UI CLONE",
      // date: "Apr 2023 - May 2023",
      description:"It is the Netflix user interface featuring many movie genres.",
    //  image:
    //     "https://user-images.githubusercontent.com/64485885/234602896-a1bd8bcc-b72b-4821-83d6-8ad885bf435e.png",
      tags: ["React Js", "Javascript", "Css","HTML" ,"Redux"],
      category: "web app",
      // github
      // member: [
       
      // ],
    },
    {
      id: 9,
      title: "MOVIE- RECOMMENDATION SYSTEM",
      // date: "Oct 2022 - Jan 2023",
      description:"Purpose: It recommends the movie based on genres and other movies.",
    image:movieRecommender,
   tags: [
      "Python ","Jupyter Lab ","Streamlit"
      ],
      category: "machine learning",
   github: "https://github.com/ashima-27/movie-recommendation-system.git",
      webapp: "https://movie-recommendation-system-2lxoc8kvy25awq8dnqqfoz.streamlit.app/",
    },
    {
      id: 10,
      title: "MUSIC PLAYER",
      // date: "Jan 2023 - Mar 2023",
      description:"It's an Android app with several features, such the ability to save locally stored audio files.  Features:play,pause,delete,next,previous,share,timer,set as    Ringtone,save and remove From Favourite.",
    //  image:
    //     "https://github.comda/Brain-Tumor-Detection/raw/main/Readme_resource/Image2.png",
      tags: ["KOtlin","XML"],
      category: "android app",
      // github: "https://github.com/chanda/Brain-Tumor-Detection",
      // webapp: "https://brain-tumor.netlify.app/",
    
    },
    {
      id: 11,
      title: "BOOK HUB",
      // date: "Dec 2021 - Apr 2022",
      description:"It Is Android Application Having different Functionality such as add    And remove From favourite.",
    // image:
    //     "https://camo.githubusercontent.com/3ad28aa710d18525f1fc87de056ed53c706d09979589bfd5a773df36653bad38/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f6c6f67696e2d65613565322e61707073706f742e636f6d2f6f2f4255434b4f49442532302831292e706e673f616c743d6d6564696126746f6b656e3d32653735376235372d323964372d346263612d613562322d653164346538313432373435",
      tags: ["Kotlin", "XML"],
      category: "android app",
      // github: "https://github.com/anda/Buckoid-Android-App",
      // webapp: "https://play.google.com/store/apps/details?id=com..buckoid",
    },
    {
      id: 12,
      title: "WEDDING PROPOSAL",
      // date: "Jun 2023 - Jul 2023",
       description:"Blissful Wedding Category Bootstrap Responsive Web Template",
     image:wedding,
    tags: ["Html","Css","Javascript","Jquery","Bootstrap"],
      category: "web app",
       github: "https://github.com/ashima-27/Wedding-Proposer.git",
       webapp: "https://guileless-bunny-dad55e.netlify.app/",
    },
    {
      id: 13,
      title: "CLASSROOM",
      // date: "Jul 2021",
      description:"ClassWork an Education Category Flat Bootstrap Responsive Web Template",
     image:classroom,
    tags: ["Html","Css","Javascript","Jquery","Bootstrap"],
      category: "web app",
       github: "https://github.com/ashima-27/Classroom.git",
       webapp: "https://ashiclassroom.netlify.app",
    },
    {
      id: 14,
      title: "Resume Maker",
      // date: "Jul 2021",
      description:"With a resume builder, you can create a professional-looking resume in a matter of minutes",
    // image:
      //   "https://firebasestorage.googleapis.com/v0/b/whatsapp-clone-.appspot.com/o/Screenshot%20(151).png?alt=media&token=48391593-1ef0-4a8c-a92a-eb82bdf38e89",
      tags: ["Html","Css","Javascript","React","Tailwind"],
      category: "web app",
       github: "https://github.com/ashima-27/Classroom.git",
       webapp: "https://ashiclassroom.netlify.app",
    },
    {
      id: 15,
      title: "ToDo ",
      // date: "Jul 2021",
       description:"Organize your tasks effortlessly with Todo App, a sleek and responsive to-do app built with React.",
     // image:
      //   "https://firebasestorage.googleapis.com/v0/b/whatsapp-clone-.appspot.com/o/Screenshot%20(151).png?alt=media&token=48391593-1ef0-4a8c-a92a-eb82bdf38e89",
      tags: ["Html","Css","Javascript","React","Tailwind"],
      category: "web app",
       github: "https://github.com/ashima-27/Classroom.git",
       webapp: "https://ashiclassroom.netlify.app",
    },
    {
      id: 16,
      title: "Memory Checker",
      // date: "Jul 2021",
       description:"Fun Memory Checker Brain ",
     image:memory,
    tags: ["Html","Css","Javascript","React"],
      category: "web app",
       github: "https://github.com/ashima-27/Brain-tester.git",
       webapp: "https://braintester27.netlify.app/",
    },
    {
      id: 17,
      title: "Fyle ",
      // date: "Jul 2021",
       description:"Clone of Fyle",
     image:fyle,
    tags: ["Html","Css","Javascript"],
      category: "web app",
       github: "https://github.com/ashima-27/fyle2728.git",
       webapp: "https://fyle2728.netlify.app/",
    },
    {
      id: 18,
      title: "Covid Tracker",
      // date: "Jul 2021",
       description:"Stay informed and safe with COVID Tracker, your go-to app for real-time updates on COVID-19. Built with a user-friendly interface, COVID Tracker provides the latest statistics, trends, and vaccination rates globally and locally. Track cases, recoveries, and vaccination progress effortlessly to make informed decisions and protect your health.",
     // image:
      //   "https://firebasestorage.googleapis.com/v0/b/whatsapp-clone-.appspot.com/o/Screenshot%20(151).png?alt=media&token=48391593-1ef0-4a8c-a92a-eb82bdf38e89",
      tags: ["Html","Css","Javascript","React","Tailwind"],
      category: "web app",
       github: "https://github.com/ashima-27/Classroom.git",
       webapp: "https://ashiclassroom.netlify.app",
    },
    {
      id: 19,
      title: "Moviex",
      // date: "Jul 2021",
       description:"MovieX is a dynamic and engaging movie website that provides movie enthusiasts with a wide range of movies to explore and enjoy. The website is designed to be user-friendly and easy to navigate, with a variety of features that make it simple to find the perfect movie for any occasion.",
     image:moviex,
   tags: ["Html","Css","Javascript","React","Tailwind"],
      category: "web app",
       github: "https://github.com/ashima-27/MoviX.git",
       webapp: "https://moviex27.netlify.app/",
    },
    {
      id: 20,
      title: "Stocky Town",
      // date: "Jan 2024 - Dec 2023",
      description:" An encrypted, dynamic, responsive Blogging website. It features a user's portal in addition to an admin portal.  Administrators utilise a different sign-up login.",
    //  image:
    //     ,
      tags: [
        "Html" , "Css"  , "Javascript" , "Bootstrap" , "Angular ", "Node js" , "Mongo db" ,"Express js" , "Typescript"
      ],
      category: "web app",
      // github: "
      // webapp: "https://decisionhub.netlify.app/",
    },
    {
      id: 21,
      title: "Portfolio ",
      // date: "Jul 2021",
       description:"Portfolio",
     image:portfolio,
    tags: ["React","Tailwind","Javascript"],
      category: "web app",
       github: "https://github.com/ashima-27/ashimaportfolio",
       webapp: "https://ashimaportfolio.netlify.app/",
    },
    {
      id: 22,
      title: "Edu Zone ",
      // date: "Jul 2021",
       description:" React Native Application ",
     video:eduzone,
    tags: ["React Native","Tailwind","Javascript"],
      category: "android app",
       github: "",
       webapp: "",
    },
  ];



  