import React, { useState } from "react";
import { projects } from "../../../SampleData/personalProject";
import AnimateOnScroll from "../../../components/AnimateOnScroll/AnimateOnScroll";
import LaptopShape from "../../../components/LaptopShape/LaptopShape";
import DefaultTab from "../../../components/DefaultTab/DefaultTab";
import ProjectCard from "../../../components/ProjectCard/ProjectCard"; 

const PersonalProject = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const tabs = [
    { name: 'All', category: null },
    { name: 'Web Development', category: 'web app' },
    { name: 'Mobile Development', category: 'android app' },
    { name: 'Machine Learning', category: 'machine learning' },
  ];

  const handleLaptopClick = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const renderProjects = (category) => {
    const filteredProjects = category
      ? projects.filter(project => project.category === category)
      : projects;

    return (
      <div className="flex flex-col md:flex-row flex-wrap w-full justify-center items-center">
        {filteredProjects.map((project, index) => (
        <AnimateOnScroll animationClass="animate-slide-in-from-bottom" 
            key={index}
            className="flex flex-col md:flex-row w-full md:w-6/12 lg:w-4/12 gap-2 justify-center items-center"
          >
            <LaptopShape
              name={project.title}
              image={project?.image}
              video={project?.video}
              screenColor="bg-black"
              category={project.category}
              onClick={() => handleLaptopClick(project)} 
            />
          </AnimateOnScroll>
       
        ))}
      </div>
    );
  };

  const tabsWithContent = tabs.map(tab => ({
    name: tab.name,
    content: renderProjects(tab.category),
  }));

  return (
    <div className="flex flex-col py-2 md:py-8 justify-center items-center gap-4 w-11/12 mx-auto">
      <h1 className="poppins-bold text-customdarkBlue px-2 font-bold text-3xl md:text-5xl break-words">
        <AnimateOnScroll animationClass="animate-slide-in-from-right">
          My Personal Project
        </AnimateOnScroll>
      </h1>

      <p className="small mb-4 text-lg text-customGray">
      <AnimateOnScroll animationClass="animate-slide-in-from-left">      
      Explore my portfolio showcasing diverse web development projects, including responsive designs, interactive applications, and efficient data management solutions.
       
        </AnimateOnScroll>
      </p>
      <DefaultTab tabs={tabsWithContent} dir='row' dirTop='col' />
  
      
  
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-4 w-full max-w-lg">
            <button className="absolute top-2 right-2 text-gray-500" onClick={() => setIsModalOpen(false)}>
              &times;
            </button>
            <ProjectCard data={selectedProject}  onClose={()=>{setIsModalOpen(false)}}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalProject;
