import React from "react";

const StoneShapeDiv = ({ className, children }) => {
  return (
    <div
      className={`shadow-xl transform rotate-3 ${className}`}
      style={{
        borderRadius: "60% 40% 70% 30% / 30% 70% 40% 60%", 
      }}
    >
      {children}
    </div>
  );
};

export default StoneShapeDiv;
