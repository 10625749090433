import React, { useRef, useEffect } from 'react';
import './autoResizeStyle.css';

const AutoResizeTextarea = ({ value, onChange, placeholder, className, rows=1,disabled }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      
      textarea.style.height = 'auto'; 
      textarea.style.height = `${textarea.scrollHeight}px`; 
    }
  }, [value]); 

  return (
    <textarea
      ref={textareaRef}
      className={`w-full  textarea-auto-resize ${className}`}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      style={{ overflow: 'hidden', resize: 'none' }} 
      rows={rows}
    />
  );
};

export default AutoResizeTextarea;
