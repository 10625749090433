import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import "./buttonStyle.css"
const Button = React.memo(({ onClick, buttonName, className }) => {
  return (
    <button
    onClick={onClick}
    className={classNames(
      'button-86 p-80', 
      className   
    )}
  >
      {buttonName}
    </button>

    
  );
});

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Button.defaultProps = {
  className: '',
};

export default Button;
