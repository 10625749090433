import React, { useState, useEffect } from "react";
import "./testimonials.css";
import img2 from "../../../../assets/images/view-3d-woman.jpg";
import TestimonialModal from "../../../../modals/TestimonialForm/TestimonialForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const getRandomColor = () => {
  const colors =  ['#aeb5b4', '#f6f7ff','#f6f7ff','#8d91ac','#99a0d338',
   '#a1a3a2',
   '#a9a8a6', 
  '#cdcccc',
'#e1e0de', 
 '#f8f8f8',
  '#ecebe9',
 '#cdc8c4', 
  '#ebeef2'];
  return colors[Math.floor(Math.random() * colors.length)];
};


const ShimmerCard = () => (
  <div className={`relative flex-shrink-0 w-80 p-8 rounded-lg shadow-md animate-pulse mx-4`} style={{backgroundColor:getRandomColor()}}>
    <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 w-20 h-20 rounded-full " style={{backgroundColor:getRandomColor()}} />
    <div className="pt-10 w-full p-2 rounded-lg" >
      <div className="h-6  rounded mb-2" style={{backgroundColor:getRandomColor()}}></div>
      <div className="h-4 rounded mb-2" style={{backgroundColor:getRandomColor()}}></div>
      <div className="h-4  rounded mb-4" style={{backgroundColor:getRandomColor()}}></div>
      <div className="h-6  rounded mb-2" style={{backgroundColor:getRandomColor()}}></div>
    </div>
  </div>
);

const Testimonials = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTestimonials = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/testimonials/getTestimonials`
        );
        const data = await response.json();
        setTestimonialsData(data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  const handleReview = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const addTestimonial = async (newTestimonial) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/testimonials/postTestimonials`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newTestimonial),
        }
      );

      if (response.ok) {
        toast.success("Testimonial added successfully!");
        closeModal();
      } else {
        toast.error("Failed to add testimonial.");
      }
    } catch (error) {
      console.error("Error posting testimonial:", error);
      toast.error("Failed to add testimonial.");
    }
  };

  return (
    <div className="flex flex-col w-full py-6 justify-center items-center gap-4">
      <ToastContainer />
      <h1 className="text-customBlue pt-14 font-bold text-3xl md:text-4xl px-4 md:px-0">
        What Our <span className="text-customeLightBlue">Users </span> Say About Us!
      </h1>
      <p className="text-md md:text-lg text-customWhite px-4 md:px-0">
        Discover what our satisfied clients have to say about their experiences with our Services!
      </p>
      <button
        onClick={handleReview}
        className="w-10/12 md:w-4/12 bg-customWhite text-customdarkBlue p-4 text-lg font-semibold hover:bg-customeLightBlue hover:text-customWhite"
      >
        Do You Like To Review?
      </button>

      <div className="w-full my-6">
        {loading ? (
      
          <div className="flex flex-row w-full overflow-scroll justify-center items-center hide-scrollbartest">
            <div className="flex flex mt-16 lg:justify-center justify-start w-full mb-0">
              {[...Array(6)].map((_, index) => (
                <ShimmerCard key={index} />
              ))}
            </div>
          </div>
        ) : (
          <div className="hide-scrollbartest flex flex-row w-full overflow-scroll justify-center items-center ">
            <div className="flex flex overflow-visible mt-16  hide-scrollbartest   w-full px-4">
              {testimonialsData.map((client, index) => (
                <div
                  key={index}
                  className="relative  flex-shrink-0 w-80 items-center gap-2 p-8 bg-customWhite hover:bg-custom rounded-lg shadow-md transform transition-transform hover:scale-105 overflow-visible hide-scrollbartest mx-4 group"
                >
                  <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 w-20 h-20 rounded-full bg-gray-300 overflow-hidden shadow-lg border-4 group-hover:border-customWhite border-custom">
                    {client.avatar && (
                      <img
                        src={client.avatar || img2}
                        alt={client.name}
                        className="w-full h-full object-cover transition-transform duration-500 hover:rotate-180"
                      />
                    )}
                  </div>
                  <div className="pt-10 w-full text-center">
                    <h2 className="text-xl font-bold text-customeLightBlue">
                      {client.name}
                    </h2>
                    {/* <p className="text-customdarkBlue group-hover:text-white">
                      {client.email}
                    </p> */}
                    <p className="text-customdarkBlue group-hover:text-white">
                      {client.position}
                    </p>
                    <p className="text-center text-customdarkBlue mt-4 mb-2 italic group-hover:text-white break-words">
                      “{client.message}”
                    </p>
                    <hr />
                    <div className="flex justify-center text-customdarkBlue items-center w-full text-sm text-gray-400 mt-4 group-hover:text-white">
                      <p className="text-customdarkBlue group-hover:text-white">
                        {client.project}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {isModalOpen && (
        <TestimonialModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={addTestimonial}
        />
      )}
    </div>
  );
};

export default Testimonials;
