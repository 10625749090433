import React from "react";
import AnimateOnScroll from "../../../components/AnimateOnScroll/AnimateOnScroll";
import { education } from "../../../SampleData/education"
import { InfoCard } from "../../../components/InfoCard/InfoCard";
const Education = () => {
  return (
    <div className="flex flex-col  justify-center p-2 md:p-8 w-11/12 mx-auto ">
     <h1 className="poppins-bold text-customdarkBlue px-4 font-semibold text-xl text-start md:text-3xl p-2 break-words">
     <AnimateOnScroll animationClass="animate-slide-in-from-top"> Education </AnimateOnScroll>
      </h1>
      <div className="flex flex-col md:flex-row gap-4">
      {education.map((education, index) => {
        return (  
            <AnimateOnScroll animationClass="animate-slide-in-from-bottom" className="flex flex-col md:flex-row w-full md:w-6/12 lg:w-4/12"> 
      
            <InfoCard data={education} /> 
            </AnimateOnScroll>
           )
      })}
      </div>
    </div>
  );
};

export default Education;
