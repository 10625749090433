import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faInstagramSquare, faLinkedin, faTwitter, faUpwork, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'; // Import from solid icons package

const SocialIcons = () => {
  return (
    <div className="w-full mx-auto flex flex-row flex-wrap md:justify-start justify-center gap-4">
      <a
        href="https://www.linkedin.com/in/ashima-duggal-4a71111aa/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-700 transform transition-transform duration-300 hover:scale-110  "
      >
        <FontAwesomeIcon icon={faLinkedin} size="2x" className='neumorphic-icon'/>
      </a>
      <a
        href="https://www.instagram.com/webcrafters27/profilecard/?igsh=YXZyZ2w0eGV2dWs5"
        target="_blank"
        rel="noopener noreferrer"
        className="text-orange-700 transform transition-transform duration-300 hover:scale-110  "
      >
        <FontAwesomeIcon icon={faInstagram} size="2x" className='neumorphic-icon'/>
      </a>
      <a
        href="https://x.com/ashi_2701"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-400 transform transition-transform duration-300 hover:scale-110 "
      >
        <FontAwesomeIcon icon={faTwitter} size="2x" className='neumorphic-icon'/>
      </a>
      <a
        href="https://www.upwork.com/freelancers/~01e25ee80a13941102?mp_source=share"
        target="_blank"
        rel="noopener noreferrer"
        className="text-green-600 transform transition-transform duration-300 hover:scale-110 "
      >
        <FontAwesomeIcon icon={faUpwork} size="2x" className='neumorphic-icon' />
      </a>
      <a
        href="https://wa.me/7528884302"
        target="_blank"
        rel="noopener noreferrer"
        className="text-green-400 text-sm transform transition-transform duration-300 hover:scale-110 "
      >
        <FontAwesomeIcon icon={faWhatsapp} size="2x" className='neumorphic-icon'/>
      </a>
      <a
        href="mailto:ashimaduggal2728@gmail.com"
        className="text-red-500 transform transition-transform duration-300 hover:scale-110 "
      >
        <FontAwesomeIcon icon={faEnvelope} size="2x" className='neumorphic-icon'/>
      </a>
      <a
        href="https://github.com/ashima-27"
        className="text-black-500 transform transition-transform duration-300 hover:scale-110 "
      >
        <FontAwesomeIcon icon={faGithub} size="2x" className='neumorphic-icon'/>
      </a>
    </div>
  );
};

export default SocialIcons;
