import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTitle = (defaultTitle) => {
  const location = useLocation();

  useEffect(() => {
    const pageTitleMap = {
      "/": "Home - Aashima Duggal",
      "/about": "About  - Aashima Duggal",
      "/service": "Services - Aashima Duggal",
      "/contact": "Contact Me - Aashima Duggal",
     
    };

    const matchDynamicRoute = (path) => {
      const dynamicRoutes = [
    
      ];

      for (const { pattern, title } of dynamicRoutes) {
        const regex = new RegExp(`^${pattern.replace(/:[^\s/]+/g, '[^/]+')}$`);
        if (regex.test(path)) {
          return title;
        }
      }
      return null;
    };

    const currentPath = location.pathname;
    const staticTitle = pageTitleMap[currentPath];
    const dynamicTitle = matchDynamicRoute(currentPath);

    document.title = staticTitle || dynamicTitle || defaultTitle;
  }, [location.pathname, defaultTitle]);
};

export default usePageTitle;
