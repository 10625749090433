export const skills = [
  {
    skills: [
      {
        name: "React Js",
        image:
  "https://cdn3d.iconscout.com/3d/free/thumb/free-react-3d-icon-download-in-png-blend-fbx-gltf-file-formats--facebook-logo-native-javascript-library-user-interfaces-coding-lang-pack-logos-icons-7578010.png?f=webp"   },
      {
        name: "Redux",
        image:
          "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
      },
      {
        name: "Next Js",
        image:          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACTklEQVR4Ab1XAaQqURB9DyohSykREpRIQSAlBCoECKUFCSRCBBEAaSEABQEoCIEASCwAUICALgCo83do0//9v819XX845O7VnDkzOzP7JWGaBd3C3IJpQVjAHeJ+Rs9a97vKLGrBsB1KgMhEP3FMUUwt4ENMfxr1yQIU4SSjRkbeOZtERmHk6pXQVDlnkHh9S+QLTm1hkiz4n/gzFQuny9FoFLquE+i34x+n02k0m00UCoV3BIzn3MMJrVYLtp1OJ0cS/X4f5/MZhmG8IyDsWtDfEaDIn2232/3zbrvdxuFwwGg04qRBt+VnETBNE0IIkE2n07/erdfrWK/X6Ha73Hb9ZXII3G43ivy3dNRqtZe7lUoFs9mM6oBDwCQCgquALT1FT3a5XF7qIZ/PYzgcolqtcggIIgBZAgRKB6lCRalp2uM8k8mAVMrlchwC+DEBipycE4n5fP44j8ViKJVKSCaTbAJCpgaez4vFIsjoWa/XA50FAgEkEgmEw2F2CkxZBZ5Br5tt1ITcbjd8Ph88Hg+7CBefECCsVitS4aVJcV9D/VMCVITk/Hq9YrPZyBBo2a1YMGvAcQYcj0cCtWMugcdYNhjDiBrP25mx3++x3W6RzWZZ8isfxzQLlsslJpMJpYY5jhkqcOH1ejEYDDAej9FoNOByuZxGsfqVzC7KTqcDSkkqleKsZOqX0mAwiHK5DGrJfr+fs5SqX8sjkQji8ThCoRC+v78Za7l6JagrUh3YkUuZpqgwDaecc9VYSDoV5Fg+at7n+eLN57kuE/EvzHr/Kvs31aYAAAAASUVORK5CYII=",
      },
      {
        name: "Angular Js",
        image:
 "https://cdn3d.iconscout.com/3d/free/thumb/free-angularjs-3d-icon-download-in-png-blend-fbx-gltf-file-formats--google-logo-html-angular-coding-lang-pack-logos-icons-7577994.png?f=webp" ,
      },
      {
        name: "HTML",
        image: "https://cdn3d.iconscout.com/3d/free/thumb/free-html-3d-icon-download-in-png-blend-fbx-gltf-file-formats--html5-logo-dom-markup-language-frontend-coding-lang-pack-logos-icons-7578018.png?f=webp",
      },
      {
        name: "CSS",
        image:
    "https://cdn3d.iconscout.com/3d/free/thumb/free-css-3d-icon-download-in-png-blend-fbx-gltf-file-formats--html-logo-css3-html5-cascading-style-sheets-coding-lang-pack-logos-icons-7578024.png?f=webp"  },
      {
        name: "JavaScript",
        image:
    "https://cdn3d.iconscout.com/3d/free/thumb/free-javascript-3d-icon-download-in-png-blend-fbx-gltf-file-formats--html-logo-vue-angular-coding-lang-pack-logos-icons-7577991.png?f=webp"  },
        {
      name: "JQuery",
      image:
 "https://cdn3d.iconscout.com/3d/free/thumb/free-jquery-3d-icon-download-in-png-blend-fbx-gltf-file-formats--javascript-library-html-scripting-logo-frontend-coding-lang-pack-logos-icons-7578007.png?f=webp"  
 },{  name: "Bootstrap",
        image:
  "https://cdn3d.iconscout.com/3d/free/thumb/free-bootstrap-framework-3d-icon-download-in-png-blend-fbx-gltf-file-formats--logo-dart-mobile-developer-programming-language-pack-logos-icons-5453031.png"   },
      {
        name: "Tailwind",
        image:
   "https://cdn3d.iconscout.com/3d/free/thumb/free-tailwind-3d-icon-download-in-png-blend-fbx-gltf-file-formats--html-logo-css-framework-customizable-coding-lang-pack-logos-icons-7577995.png?f=webp"  },
      {
        name: "Material UI",
        image:
   "https://mui.com/static/logo.png" },
      {
        name: "XML",
        image:
   "https://static.vecteezy.com/system/resources/previews/021/951/538/original/3d-xml-file-icon-illustration-png.png"  },
      {
        name: "React Native",
        image:
   "https://cdn3d.iconscout.com/3d/free/thumb/free-react-native-3d-logo-download-in-png-blend-fbx-gltf-file-formats--software-social-media-pack-logos-4642743.png?f=webp"  },
    ,{
      name: "Node Js",
      image: "https://rndexperts.com/wp-content/uploads/2023/11/node-js-logo-1.webp",
    },
    {
      name: "Express Js",
      image:
 "https://cdn.prod.website-files.com/609bc2fa29b6d5b7f44a2785/647743f51bc76753239a8bc6_expressjs-logo.webp" },
    {
      name: "MongoDB",
      image:
"https://cdn3d.iconscout.com/3d/free/thumb/free-mongo-db-3d-icon-download-in-png-blend-fbx-gltf-file-formats--mongodb-database-document-oriented-nosql-coding-lang-pack-logos-icons-7577996.png?f=webp"   },
    {
      name: "MySql",
      image:
 "https://cdn3d.iconscout.com/3d/free/thumb/free-mysql-3d-icon-download-in-png-blend-fbx-gltf-file-formats--database-relational-sql-coding-lang-pack-logos-icons-7578013.png?f=webp" },
   
    ],
  },
  {
    skills:[
      {
        name:"C++",
        image:
    "https://cdn3d.iconscout.com/3d/free/thumb/free-s-3d-icon-download-in-png-blend-fbx-gltf-file-formats--c-plus-logo-programming-language-coding-lang-pack-logos-icons-7578015.png?f=webp"  }
    ,
    {
      name: "Java",
      image:
"https://cdn3d.iconscout.com/3d/premium/thumb/java-3d-icon-download-in-png-blend-fbx-gltf-file-formats--logo-programming-coding-development-pack-website-icons-5709019.png?f=webp"},  {
      name: "Kotlin",
      image:
  "https://cdn3d.iconscout.com/3d/free/thumb/free-kotlin-3d-logo-download-in-png-blend-fbx-gltf-file-formats--android-programming-app-social-media-pack-company-brand-logos-4781228.png"},
    {
      name: "Python",
      image:
 "https://cdn3d.iconscout.com/3d/premium/thumb/python-3d-icon-download-in-png-blend-fbx-gltf-file-formats--logo-development-code-programming-computer-science-pack-technology-icons-5602757.png?f=webp" },
   ,    {
    name: "Jupyter",
    image:
"https://static-00.iconduck.com/assets.00/jupyter-icon-437x512-1al3l4x3.png"  },
 
  {
    name:"Pandas",
    image:"https://raw.githubusercontent.com/gilbarbara/logos/main/logos/pandas-icon.svg"
  },
  {
    name:"Numpy",
    image:
 "https://cdn3d.iconscout.com/3d/free/thumb/free-numpy-3d-icon-download-in-png-blend-fbx-gltf-file-formats--python-logo-library-coding-lang-pack-logos-icons-7578012.png?f=webp"},
  {
    name:"Matplotlib",
    image:"https://raw.githubusercontent.com/devicons/devicon/master/icons/matplotlib/matplotlib-plain-wordmark.svg"
  }, {
    name: "Git",
    image:
"https://cdn3d.iconscout.com/3d/free/thumb/free-git-3d-icon-download-in-png-blend-fbx-gltf-file-formats--github-logo-version-control-system-vcs-coding-lang-pack-logos-icons-7578021.png?f=webp" },
  {
    name: "GitHub",
    image:
"https://cdn3d.iconscout.com/3d/free/thumb/free-github-3d-logo-download-in-png-blend-fbx-gltf-file-formats--social-media-pack-logos-2447911.png?f=webp" },
  {
    name: "Netlify",
    image:
      "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
  },
  {
    name: "VS Code",
    image:
"https://cdn3d.iconscout.com/3d/free/thumb/free-visual-studio-code-3d-icon-download-in-png-blend-fbx-gltf-file-formats--microsoft-logo-python-java-c-coding-lang-pack-logos-icons-7578027.png?f=webp"  },
  {
    name: "Postman",
    image:
      "https://static-00.iconduck.com/assets.00/postman-icon-497x512-beb7sy75.png",
  },
  {
    name: "Android Studio",
    image:
"https://icon-library.com/images/android-studio-icon/android-studio-icon-8.jpg" },
  {
    name: "Canva",
    image:
"https://cdn3d.iconscout.com/3d/free/thumb/free-canva-3d-icon-download-in-png-blend-fbx-gltf-file-formats--logo-design-soft-tool-pack-logos-icons-7516879.png?f=webp"  },
  ,
  {
    name: "Figma",
    image:
"https://cdn3d.iconscout.com/3d/free/thumb/free-figma-3d-logo-download-in-png-blend-fbx-gltf-file-formats--software-social-media-technology-pack-company-brand-logos-4642727.png"},
   ]
  },
];


export const skillsAbout = [
  {
    category: "Frontend",
    skills: [
      {
        name: "React Js",
        image:
  "https://cdn3d.iconscout.com/3d/free/thumb/free-react-3d-icon-download-in-png-blend-fbx-gltf-file-formats--facebook-logo-native-javascript-library-user-interfaces-coding-lang-pack-logos-icons-7578010.png?f=webp"   },
      {
        name: "Redux",
        image:
          "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
      },
      {
        name: "Next Js",
        image:          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACTklEQVR4Ab1XAaQqURB9DyohSykREpRIQSAlBCoECKUFCSRCBBEAaSEABQEoCIEASCwAUICALgCo83do0//9v819XX845O7VnDkzOzP7JWGaBd3C3IJpQVjAHeJ+Rs9a97vKLGrBsB1KgMhEP3FMUUwt4ENMfxr1yQIU4SSjRkbeOZtERmHk6pXQVDlnkHh9S+QLTm1hkiz4n/gzFQuny9FoFLquE+i34x+n02k0m00UCoV3BIzn3MMJrVYLtp1OJ0cS/X4f5/MZhmG8IyDsWtDfEaDIn2232/3zbrvdxuFwwGg04qRBt+VnETBNE0IIkE2n07/erdfrWK/X6Ha73Hb9ZXII3G43ivy3dNRqtZe7lUoFs9mM6oBDwCQCgquALT1FT3a5XF7qIZ/PYzgcolqtcggIIgBZAgRKB6lCRalp2uM8k8mAVMrlchwC+DEBipycE4n5fP44j8ViKJVKSCaTbAJCpgaez4vFIsjoWa/XA50FAgEkEgmEw2F2CkxZBZ5Br5tt1ITcbjd8Ph88Hg+7CBefECCsVitS4aVJcV9D/VMCVITk/Hq9YrPZyBBo2a1YMGvAcQYcj0cCtWMugcdYNhjDiBrP25mx3++x3W6RzWZZ8isfxzQLlsslJpMJpYY5jhkqcOH1ejEYDDAej9FoNOByuZxGsfqVzC7KTqcDSkkqleKsZOqX0mAwiHK5DGrJfr+fs5SqX8sjkQji8ThCoRC+v78Za7l6JagrUh3YkUuZpqgwDaecc9VYSDoV5Fg+at7n+eLN57kuE/EvzHr/Kvs31aYAAAAASUVORK5CYII=",
      },
      {
        name: "Angular Js",
        image:
 "https://cdn3d.iconscout.com/3d/free/thumb/free-angularjs-3d-icon-download-in-png-blend-fbx-gltf-file-formats--google-logo-html-angular-coding-lang-pack-logos-icons-7577994.png?f=webp" ,
      },
      {
        name: "HTML",
        image: "https://cdn3d.iconscout.com/3d/free/thumb/free-html-3d-icon-download-in-png-blend-fbx-gltf-file-formats--html5-logo-dom-markup-language-frontend-coding-lang-pack-logos-icons-7578018.png?f=webp",
      },
      {
        name: "CSS",
        image:
    "https://cdn3d.iconscout.com/3d/free/thumb/free-css-3d-icon-download-in-png-blend-fbx-gltf-file-formats--html-logo-css3-html5-cascading-style-sheets-coding-lang-pack-logos-icons-7578024.png?f=webp"  },
      {
        name: "JavaScript",
        image:
    "https://cdn3d.iconscout.com/3d/free/thumb/free-javascript-3d-icon-download-in-png-blend-fbx-gltf-file-formats--html-logo-vue-angular-coding-lang-pack-logos-icons-7577991.png?f=webp"  },
        {
      name: "JQuery",
      image:
 "https://cdn3d.iconscout.com/3d/free/thumb/free-jquery-3d-icon-download-in-png-blend-fbx-gltf-file-formats--javascript-library-html-scripting-logo-frontend-coding-lang-pack-logos-icons-7578007.png?f=webp"  
 },{  name: "Bootstrap",
        image:
  "https://cdn3d.iconscout.com/3d/free/thumb/free-bootstrap-framework-3d-icon-download-in-png-blend-fbx-gltf-file-formats--logo-dart-mobile-developer-programming-language-pack-logos-icons-5453031.png"   },
      {
        name: "Tailwind",
        image:
   "https://cdn3d.iconscout.com/3d/free/thumb/free-tailwind-3d-icon-download-in-png-blend-fbx-gltf-file-formats--html-logo-css-framework-customizable-coding-lang-pack-logos-icons-7577995.png?f=webp"  },
      {
        name: "Material UI",
        image:
   "https://mui.com/static/logo.png" },
      {
        name: "XML",
        image:
   "https://static.vecteezy.com/system/resources/previews/021/951/538/original/3d-xml-file-icon-illustration-png.png"  },
      {
        name: "React Native",
        image:
   "https://cdn3d.iconscout.com/3d/free/thumb/free-react-native-3d-logo-download-in-png-blend-fbx-gltf-file-formats--software-social-media-pack-logos-4642743.png?f=webp"  },
    
    ],
  },
  {
    category: "Backend",
    skills: [
      {
        name: "Node Js",
        image: "https://rndexperts.com/wp-content/uploads/2023/11/node-js-logo-1.webp",
      },
      {
        name: "Express Js",
        image:
    "https://cdn.prod.website-files.com/609bc2fa29b6d5b7f44a2785/647743f51bc76753239a8bc6_expressjs-logo.webp" },
    
    ],
  },
  {
    category: "DataBase",
    skills: [
      {
        name: "MongoDB",
        image:
  "https://cdn3d.iconscout.com/3d/free/thumb/free-mongo-db-3d-icon-download-in-png-blend-fbx-gltf-file-formats--mongodb-database-document-oriented-nosql-coding-lang-pack-logos-icons-7577996.png?f=webp"   },
      {
        name: "MySql",
        image:
   "https://cdn3d.iconscout.com/3d/free/thumb/free-mysql-3d-icon-download-in-png-blend-fbx-gltf-file-formats--database-relational-sql-coding-lang-pack-logos-icons-7578013.png?f=webp" },
     
    ],
  },
  {
    category:"Programming Language",
    skills:[
      {
        name:"C++",
        image:
    "https://cdn3d.iconscout.com/3d/free/thumb/free-s-3d-icon-download-in-png-blend-fbx-gltf-file-formats--c-plus-logo-programming-language-coding-lang-pack-logos-icons-7578015.png?f=webp"  }
    ,
    {
      name: "Java",
      image:
"https://cdn3d.iconscout.com/3d/premium/thumb/java-3d-icon-download-in-png-blend-fbx-gltf-file-formats--logo-programming-coding-development-pack-website-icons-5709019.png?f=webp"}, {
      name: "Kotlin",
      image:
  "https://cdn3d.iconscout.com/3d/free/thumb/free-kotlin-3d-logo-download-in-png-blend-fbx-gltf-file-formats--android-programming-app-social-media-pack-company-brand-logos-4781228.png"},
    {
      name: "Python",
      image:
 "https://cdn3d.iconscout.com/3d/premium/thumb/python-3d-icon-download-in-png-blend-fbx-gltf-file-formats--logo-development-code-programming-computer-science-pack-technology-icons-5602757.png?f=webp" },
    ]
  },
 
 
  {
    category: "Machine Learning",
    skills: [
      {
        name: "Python",
        image:
 "https://cdn3d.iconscout.com/3d/premium/thumb/python-3d-icon-download-in-png-blend-fbx-gltf-file-formats--logo-development-code-programming-computer-science-pack-technology-icons-5602757.png?f=webp" },
   
     
      {
        name: "Jupyter",
        image:
   "https://static-00.iconduck.com/assets.00/jupyter-icon-437x512-1al3l4x3.png"  },
     
      {
        name:"Pandas",
        image:"https://raw.githubusercontent.com/gilbarbara/logos/main/logos/pandas-icon.svg"
      },
      {
        name:"Numpy",
        image:
     "https://cdn3d.iconscout.com/3d/free/thumb/free-numpy-3d-icon-download-in-png-blend-fbx-gltf-file-formats--python-logo-library-coding-lang-pack-logos-icons-7578012.png?f=webp"},
      {
        name:"Matplotlib",
        image:"https://raw.githubusercontent.com/devicons/devicon/master/icons/matplotlib/matplotlib-plain-wordmark.svg"
      },
      
    ],
  },
 
  {
    category: "Others",
    skills: [
      {
        name: "Git",
        image:
    "https://cdn3d.iconscout.com/3d/free/thumb/free-git-3d-icon-download-in-png-blend-fbx-gltf-file-formats--github-logo-version-control-system-vcs-coding-lang-pack-logos-icons-7578021.png?f=webp" },
      {
        name: "GitHub",
        image:
   "https://cdn3d.iconscout.com/3d/free/thumb/free-github-3d-logo-download-in-png-blend-fbx-gltf-file-formats--social-media-pack-logos-2447911.png?f=webp" },
      {
        name: "Netlify",
        image:
          "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
      },
      {
        name: "VS Code",
        image:
  "https://cdn3d.iconscout.com/3d/free/thumb/free-visual-studio-code-3d-icon-download-in-png-blend-fbx-gltf-file-formats--microsoft-logo-python-java-c-coding-lang-pack-logos-icons-7578027.png?f=webp"  },
      {
        name: "Postman",
        image:
          "https://static-00.iconduck.com/assets.00/postman-icon-497x512-beb7sy75.png",
      },
      {
        name: "Android Studio",
        image:
   "https://icon-library.com/images/android-studio-icon/android-studio-icon-8.jpg" },
      {
        name: "Canva",
        image:
   "https://cdn3d.iconscout.com/3d/free/thumb/free-canva-3d-icon-download-in-png-blend-fbx-gltf-file-formats--logo-design-soft-tool-pack-logos-icons-7516879.png?f=webp"  },
      ,
      {
        name: "Figma",
        image:
 "https://cdn3d.iconscout.com/3d/free/thumb/free-figma-3d-logo-download-in-png-blend-fbx-gltf-file-formats--software-social-media-technology-pack-company-brand-logos-4642727.png"}, ],
  },
];