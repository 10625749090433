import React from "react";
import { experiences } from "../../../SampleData/workExperience";
import { InfoCard } from "../../../components/InfoCard/InfoCard";
import AnimateOnScroll from "../../../components/AnimateOnScroll/AnimateOnScroll";
const WorkExperience = () => {
  return (
    <div className="flex flex-col  justify-center  w-11/12 p-2 md:p-8 mx-auto ">
      <h1 className="poppins-bold text-customdarkBlue px-4 font-semibold text-xl text-start md:text-3xl p-2 break-words">
        <AnimateOnScroll animationClass="animate-slide-in-from-top">
          {" "}
          Work Experience{" "}
        </AnimateOnScroll>
      </h1>

      <div className="flex flex-col md:flex-row w-full gap-4">
        {experiences.map((experience, index) => {
          return (
            <AnimateOnScroll
              animationClass="animate-slide-in-from-bottom"
              className="flex flex-col md:flex-row w-full md:w-6/12 lg:w-4/12"
            >
              <InfoCard data={experience} />
            </AnimateOnScroll>
          );
        })}
      </div>
    </div>
  );
};

export default WorkExperience;
