import React, { useState,useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash/debounce';
import {
  faSearch,
  faShoppingCart,
  faUser,
  faSignOutAlt,
  faSignInAlt,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import log from "../../assets/images/log.png"
import ContactBar from '../contactbar/contactbar';
const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  const handleCartClick = () => setIsSidebarOpen(true);
  const handleCloseSidebar = () => setIsSidebarOpen(false);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = debounce((query) => {
    if (query.trim()) {
      navigate(`/search/${encodeURIComponent(searchValue)}`);
    }
  }, 600);
  const [showContactBar, setShowContactBar] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { 
        setShowContactBar(false);
      } else {
        setShowContactBar(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className='fixed z-[1] w-full'>
      {showContactBar && <ContactBar />}
      <nav className="bg-customBlue shadow-lg  w-full mx-auto px-2 sm:px-6 lg:px-8 ">
      
        <div className="w-full mx-auto px-2 sm:px-6 lg:px-8 ">
          <div className="relative flex items-center justify-between w-full h-24">
            <div className="flex items-center">
              <NavLink to="/" className="font-bold text-customdarkBlue  text-lg md:text-2xl">
              <div className='flex flex-row gap-2 justify-center items-center logo_font'>
              <img src={log} alt="logo" className='w-8 md:w-10 h-8 md:h-10 rounded-lg'/> ashima duggal
              </div>
              </NavLink>
            </div>

            <div className="hidden sm:flex sm:ml-auto space-x-4">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `px-3 py-2 rounded-md text-xl font-medium nav_item ${
                    isActive ? 'text-customdarkBlue  font-bold ' : 'text-[#6c6c6c]'
                  } hover:text-black`
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `px-3 py-2 rounded-md text-xl font-medium  nav_item ${
                    isActive ? 'text-customdarkBlue  font-bold ' : 'text-[#6c6c6c]'
                  } hover:text-black`
                }
              >
                About
              </NavLink>
              <NavLink
                to="/service"
                className={({ isActive }) =>
                  `px-3 py-2 rounded-md text-xl font-medium nav_item ${
                    isActive ? 'text-customdarkBlue  font-bold ' : 'text-[#6c6c6c]'
                  } hover:text-black`
                }
              >
                Services
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `px-3 py-2 rounded-md text-xl font-medium nav_item ${
                    isActive ? 'text-customdarkBlue  font-bold ' : 'text-[#6c6c6c]'
                  } hover:text-black`
                }
              >
                Contact
              </NavLink>
            </div>

            {/* <div className="hidden sm:flex sm:ml-auto space-x-4">
              <div className="flex flex-col md:flex-row gap-4 space-x-4 text-white">
                <div className="flex items-center w-full bg-white border border-gray-300 rounded-md overflow-hidden">
                  <span className="px-2 text-black bg-white">
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                  <input
                    id="searchInput"
                    type="text"
                    placeholder="Search..."
                    className="w-full px-2 py-2 text-black focus:outline-none"
                    value={searchValue}
                    onChange={handleSearchChange}
                  />
                  {searchValue.length > 0 && (
                    <button
                      type="button"
                      onClick={() => {
                        setSearchValue('');
                        navigate(`/`);
                      }}
                      className="text-xl font-bold text-black hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xl px-4 py-2"
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
            </div> */}

            <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-customWhite bg-customdarkBlue focus:outline-none "
                aria-expanded={isOpen}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg
                  className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div   className={`${
    isOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
  } sm:hidden overflow-hidden transition-all duration-500 ease-in-out transform`}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            <NavLink
             onClick={() => setIsOpen(false)} 
              to="/"
              className={({ isActive }) =>
                `block px-3 py-2 rounded-md text-base font-medium nav_item ${
                  isActive ? 'text-customdarkBlue  font-bold ' : 'text-[#6c6c6c]'
                } hover:text-black`
              }
            >
              Home
            </NavLink>
            <NavLink
             onClick={() => setIsOpen(false)} 
              to="/about"
              className={({ isActive }) =>
                `block px-3 py-2 rounded-md text-base font-medium nav_item ${
                  isActive ? 'text-customdarkBlue  font-bold ' : 'text-[#6c6c6c]'
                } hover:text-black`
              }
            >
              About
            </NavLink>
            <NavLink
              to="/service"
              onClick={() => setIsOpen(false)} 
              className={({ isActive }) =>
                `block px-3 py-2 rounded-md text-base font-medium nav_item ${
                  isActive ? 'text-customdarkBlue  font-bold ' : 'text-[#6c6c6c]'
                } hover:text-black`
              }
            >
              Services
            </NavLink>
            <NavLink
             onClick={() => setIsOpen(false)} 
              to="/contact"
              className={({ isActive }) =>
                `block px-3 py-2 rounded-md text-base font-medium nav_item ${
                  isActive ? 'text-customdarkBlue font-bold ' : 'text-[#6c6c6c]'
                } hover:text-black`
              }
            >
              Contact
            </NavLink>
          </div>
        </div>
      </nav>
      
    </div>
  );
};

export default Navbar;
