import React from "react";
import notFound from "../../assets/gif/File Not Found Animated 3D Icon.gif";

const LaptopShape = ({ image, name, onClick, category ,video}) => {
  return (
    <div
      className="flex flex-col m-2 items-center justify-center my-10 w-full max-w-[500px] sm:w-[80%] md:w-[500px] lg:w-[500px] h-[250px] sm:h-[300px] md:h-[320px] relative cursor-pointer "
      onClick={onClick}
    >
      <h1 className="text-sm p-2 text-customdarkBlue font-bold">{name}</h1>

      {category === 'android app' ? <>
      
        <div className="w-1/2 h-[80%] bg-gray-800 rounded-lg shadow-lg relative">
        <div className="flex items-center justify-center text-white text-2xl h-full">
        {video ? (
  <video 
    src={video} 
    alt="video" 
    controls 
    autoPlay
    className="w-full p-2 h-full object-contain"
  >
    Your browser does not support the video tag.
  </video>
) : (
  <img 
    src={notFound} 
    alt="not found" 
    className="w-full p-2 h-full object-contain" 
  />
)}

        </div>
      </div>
      
      </>  : <>
      <div className="w-full h-[80%] bg-gray-800 rounded-t-lg shadow-lg relative">
        <div className="flex items-center justify-center text-white text-2xl h-full">
          <img
            src={image ? image : notFound}
            alt={image ? "project" : "not found"}
            className="w-full p-2 h-full object-contain"
          />
        </div>
      </div>

      <div className="w-[90%] h-[5%] bg-gray-700 rounded-b-lg absolute bottom-[-3px] shadow-md"></div>

      <div className="absolute bottom-[8px] w-[90%] h-[5px] bg-gray-900 rounded-md"></div>
      </>}
      
    </div>
  );
};

export default LaptopShape;
