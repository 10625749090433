import React from "react";
import logo from "../../assets/images/log.png"

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-custom">
     
     <div className="w-16 h-16 bg-blue-500 rounded-full animate-breathing"> 
        <img
          src={logo}
          alt="Loader Logo"
          className="h-full w-full object-cover rounded-md" 
        />
      </div>
    </div>
  );
};

export default Loader;
