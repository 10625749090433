import React from "react";
import "./mytools.css";
import AnimateOnScroll from "../../../../components/AnimateOnScroll/AnimateOnScroll";
import DefaultTab from "../../../../components/DefaultTab/DefaultTab";

const MyAboutTools = ({ skills }) => {
  const tabs = [
    

    { name: 'Frontend Development', category: 'Frontend' },
    { name: 'Backend Development', category: 'Backend' },
   
  
    { name: 'Programming Languages', category: 'Programming Language' },
    {name:'Machine Learning',category:'Machine Learning'},
    { name: 'DataBase', category: 'DataBase' },
    { name: 'Tools', category: 'Others' },
  ];

  const renderSkills = (category,name) => {
    const filteredSkills = category
      ? skills.filter(skill => skill.category === category)
      : skills;

      return (
        <div className=" rounded-lg  px-4">
          {filteredSkills.map((slide, index) => (
            <div key={index} className="grid grid-cols-2 md:grid-cols-3 md:grid-cols-4 gap-4">
              {slide.skills.map((skill, skillIndex) => (
                <div
                  key={skillIndex}
                  className="  flex flex-col md:flex-col lg:flex-row items-center justify-center rounded-md p-2 m-2 shadow-md bg-custom w-full break-words transform transition-transform duration-300 hover:scale-110"
                >
                 
                  <div className="w-4/12">
                    <img
                      src={skill.image}
                      alt={skill.name}
                      className="w-auto h-auto object-contain"
                    />
                  </div>
                 <div className="w-8/12 mt-2">
                    <p className="text-center text-customBlue text-sm sm:text-lg font-semibold">
                      {skill.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
      
  };

  const tabsWithContent = tabs.map((tab) => ({
    name: tab.name,
    content: renderSkills(tab.category,tab.name),
  }));

  return (
    <div className="flex  flex-col py-2 md:py-14 justify-center items-center gap-6 w-full">
      <h1 className="poppins-bold text-customBlue px-2 py-2 my-8 font-bold text-3xl md:text-5xl break-words">
        <AnimateOnScroll animationClass="animate-zoom-in">
          My <span className="text-customeLightBlue">Tech </span> Stack
        </AnimateOnScroll>
      </h1>
      <div className="w-11/12 mx-auto">
        <DefaultTab tabs={tabsWithContent} dir="col" dirTop="row" widthClass="w-full text-customWhite" />
      </div>
    </div>
  );
};

export default MyAboutTools;
