import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import notFound from "../../assets/gif/File Not Found Animated 3D Icon.gif";
import Button from "../Button/Button";

const ProjectCard = ({ data, onClose }) => {
  const navigate = useNavigate();
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-[999999] p-4">
      <div className="relative bg-customWhite p-4 md:p-6 rounded-lg flex flex-col justify-center shadow-lg w-full max-w-md md:max-w-lg h-5/6 z-[999999]">
        <h2 className=" absolute text-xl font-bold top-4 left-4  text-start text-customdarkBlue">
          {data?.title}
        </h2>
        <button
          className="absolute text-3xl font-bold top-2 right-4  text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          &times;
        </button>

        <div className="mt-8 flex justify-start flex-col w-full overflow-scroll hide-scrollbar max-h-5/6">
          <div className="flex flex-row gap-2 w-full justify-center items-center">
            {data?.image ? (
              <img
                src={data.image}
                alt="Project"
                className="w-100 object-cover h-56"
              />
            ) : data?.video ? (
              <video
                src={data.video}
                controls
                autoPlay
                className="w-full h-56 object-contain"
              />
            ) : (
              <img
                src={notFound}
                alt="Not Found"
                className="w-100 object-cover h-56"
              />
            )}
          </div>

          <div className="mt-4 text-start flex flex-row flex-wrap gap-2">
            {data.tags.length > 0
              ? data.tags.map((skill, index) => (
                  <div
                    key={index}
                    className="bg-customdarkBlue text-customBlue rounded-md p-2 mb-2"
                  >
                    {skill}
                  </div>
                ))
              : ""}
          </div>
          {data.description && (
            <p className="m-2 text-customGray text-start">
              {data?.description || ""}
            </p>
          )}

          <div className="flex flex-col md:flex-row  py-3 gap-6 items-center w-10/12 md:w-11/12 justify-center items-center mx-auto md:mx-0">
            {data?.github && (
              <Button
                buttonName={"Github"}
                className="w-8/12"
                onClick={() => {
                  window.open(data?.github, "_blank");
                }}
              />
            )}
            {data?.webapp && (
              <Button
                buttonName={"View Project"}
                className="w-8/12"
                onClick={() => {
                  window.open(data?.webapp, "_blank");
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
