import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from '../bars/navbar/navbar';  
import usePageTitle from './PageTitle';
import Loader from "../components/Loader/Loader"
import { ToastContainer } from 'react-toastify';
import MyAboutTools from '../layouts/Footer/HomePage/MyTools/MyAboutTools';
import { skillsAbout } from "../SampleData/techStack"
import WorkExperience from '../layouts/AboutPage/WorkExperience/WorkExperience';
import PersonalProject from '../layouts/AboutPage/PersonalProject/PersonalProject';
import Education from '../layouts/AboutPage/Education/Education';
import Testimonials from '../layouts/Footer/HomePage/Testimonials/Testimonials';
const Home = lazy(() => import('../pages/Home/Home'));
const About = lazy(() => import('../pages/AboutUs/About'));
const Services = lazy(() => import('../pages/Services/Services'));
const Contact = lazy(() => import('../pages/ContactUs/Contact'));

const AppRoutes = () => {
    usePageTitle("Ashima Duggal");
  
    return (
        <>
            <Navbar /> 
          <ToastContainer/>
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route path="/" element={<Home />} />    
                    <Route path="/about" element={<About />} />       
                    <Route path="/service" element={<Services />} />       
                    <Route path="/contact" element={<Contact />} />    
                    <Route path="/techStack" element={<div className="bg-customdarkBlue  mt-32 md:mt-28"><MyAboutTools skills={skillsAbout} /></div>}/>         
                    <Route path="/experience" element={<div className="bg-customWhite  mt-32 md:mt-36"><WorkExperience/></div>}/>         
                    <Route path="/projects" element={<div className="bg-customWhite  mt-32 md:mt-36"><PersonalProject/></div>}/>         
                    <Route path="/education" element={<div className="bg-customWhite  mt-32 md:mt-36"><Education/></div>}/> 
                    <Route path="/testimonials" element={<div className="bg-customdarkBlue  mt-32 md:mt-28"><Testimonials/></div>}/> 
              
                </Routes>
            </Suspense>
        </>
    );
};

export default AppRoutes;
