import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faTwitter,
  faUpwork,
  faWhatsapp,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"; // Import from solid icons package

import AnimateOnScroll from "../../components/AnimateOnScroll/AnimateOnScroll";

const Footer = () => {
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  React.useEffect(() => {
    scrollToTop();
  }, [location]);

  return (
    <footer className="flex flex-col justify-center pt-5 w-full text-white bg-customdarkBlue ">
      <AnimateOnScroll animationClass="animate-slide-in-from-bottom">
        <div className="flex flex-col justify-center items-center w-10/12 mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10 p-5 mt-3">
           
              <div className="flex flex-row">
                <div className="flex flex-col justify-center  items-center md:items-start ">
                  <h1 className="mb-4 text-lg font-bold headings ">
                    Aashima Duggal
                  </h1>
                  <p className="text-start">
                    Experienced full-stack freelancer specializing in delivering
                    tailored web solutions that elevate brands and enhance user
                    experiences.
                  </p>
                </div>
              </div>
            

            <div className="flex flex-col justify-center  items-center ">
              {" "}
              <h2 className="mb-4 text-lg font-bold headings">Quick Links</h2>
              <div className="flex flex-row gap-2 justify-between w-full">
              <ul className="flex flex-col  justify-between flex-wrap gap-2 w-1/2 md:w-full lg:w-1/2">
                <li className="mb-2">
                  <NavLink to="/about" onClick={scrollToTop} className="foots">
                    About
                  </NavLink>
                </li>
                <li className="mb-2">
                  <NavLink
                    to="/service"
                    onClick={scrollToTop}
                    className="foots"
                  >
                    Services
                  </NavLink>
                </li>
                <li className="mb-2">
                  <NavLink
                    to="/contact"
                    onClick={scrollToTop}
                    className="foots"
                  >
                    Contact
                  </NavLink>
                </li>
                <li className="mb-2">
                  <NavLink
                    to="/projects"
                    onClick={scrollToTop}
                    className="foots"
                  >
                    Projects
                  </NavLink>
                </li>
              </ul>

              <ul className="flex flex-col justify-between flex-wrap gap-2 w-1/2 md:w-full lg:w-1/2">
                <li className="mb-2">
                  <NavLink
                    to="/testimonials"
                    onClick={scrollToTop}
                    className="foots"
                  >
                    Testimonials
                  </NavLink>
                </li>
                <li className="mb-2">
                  <NavLink
                    to="/education"
                    onClick={scrollToTop}
                    className="foots"
                  >
                    Education
                  </NavLink>
                </li>
                <li className="mb-2">
                  <NavLink
                    to="/experience"
                    onClick={scrollToTop}
                    className="foots"
                  >
                    Experience
                  </NavLink>
                </li>
                <li className="mb-2">
                  <NavLink
                    to="/techStack"
                    onClick={scrollToTop}
                    className="foots"
                  >
                    TechStack
                  </NavLink>
                </li>
              </ul>
</div>
            </div>

            <div className="flex flex-col w-full overflow-auto hide-scrollbar">
              {/* <h2 className="mb-4 text-lg font-bold headings">Say Hello !</h2> */}
              <ul className="flex flex-row md:flex-col justify-between flex-wrap gap-2">
                {/* Email */}
                <li className=" ">
                <div className="flex flex-row justify-center items-center gap-2 ">
                  <a
                    href="mailto:ashimaduggal2728@gmail.com"
                    className="text-customWhite transform transition-transform duration-300 hover:scale-110"
                  >
                    <FontAwesomeIcon icon={faEnvelope}  className="simple-icon " />
                  </a>
                  <p className="break-words">
                    ashimaduggal2728@gmail.com
                  </p>
                  </div>
                </li>

                {/* Phone */}
                <li className=" ">
                <div className="flex flex-row items-center gap-2 justify-center ">
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="lg"
                   className="simple-icon text-customWhite"
                  />
                  <span>+91 7528884302</span>
                  </div>
                </li>

                {/* Location */}
                <li className="">
                <div className="flex flex-row items-center gap-2  justify-center ">
                <p className="text-customWhite ">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    size="lg"
                    
                    // className="text-green-500"
                    className="simple-icon text-md "
                  />
                  </p>
                  <span>Jalandhar, Punjab</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </AnimateOnScroll>
      <div className="wave">
      <div className=" p-6 w-full gap-4 flex-col justify-center items-center mt-6 bg-customWhite ">
        <div className="w-full my-4 mx-auto flex flex-row flex-wrap items-center  justify-center gap-4">
          <a
            href="https://www.linkedin.com/in/ashima-duggal-4a71111aa/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700 transform transition-transform duration-300 hover:scale-110  "
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" className="simple-icon "/>
          </a>
          <a
        href="https://www.instagram.com/webcrafters27/profilecard/?igsh=YXZyZ2w0eGV2dWs5"
        target="_blank"
        rel="noopener noreferrer"
        className="text-orange-700 transform transition-transform duration-300 hover:scale-110  "
      >
        <FontAwesomeIcon icon={faInstagram} size="2x" className='neumorphic-icon'/>
      </a>
          <a
            href="https://x.com/ashi_2701"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 transform transition-transform duration-300 hover:scale-110 "
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" className="simple-icon "/>
          </a>
          <a
            href="https://www.upwork.com/freelancers/~01e25ee80a13941102?mp_source=share"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-600 transform transition-transform duration-300 hover:scale-110 "
          >
            <FontAwesomeIcon icon={faUpwork} size="2x" className="simple-icon "/>
          </a>
          <a
            href="https://wa.me/7528884302"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-600 text-sm transform transition-transform duration-300 hover:scale-110 "
          >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" className="simple-icon "/>
          </a>
          <a
            href="mailto:ashimaduggal2728@gmail.com"
            className="text-red-500 transform transition-transform duration-300 hover:scale-110 "
          >
            <FontAwesomeIcon icon={faEnvelope} size="2x" className="simple-icon "/>
          </a>
          <a
            href="https://github.com/ashima-27"
            className="text-black hover:text-gray-400 transform transition-transform duration-300 hover:scale-110 "
          >
            <FontAwesomeIcon icon={faGithub} size="2x" className="simple-icon " />
          </a>
        </div>
        <div className="flex items-center justify-center ">
          <p className="text-gray-400">
            © 2024 Aashima Duggal. All rights reserved.
          </p>
        </div>
      </div>
      </div>
    </footer>
  );
};

export default Footer;
