import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import StoneShapeDiv from '../../components/StoneShapeDiv/StoneShapeDiv';

const ContactBar = () => {
  return (
    <div className="bg-customdarkBlue py-2 px-4 w-full flex flex-row  items-center justify-between gap-4 overflow-x-scroll hide-scrollbar">
    
      <div className="flex items-center w-full justify-start">
        {/* <StoneShapeDiv className='p-1 md:p-2 border-2 border-white small'>
          <FontAwesomeIcon icon={faEnvelope} className="text-white text-xs md:text-sm" />
        </StoneShapeDiv> */}
        <a href="mailto:ashimaduggal2728@gmail.com" className="text-white small hover:underline text-xs md:text-sm ml-2">ashimaduggal2728@gmail.com</a>
      </div>

    
      <div className="flex items-center w-full justify-end flex-nowrap">
        {/* <StoneShapeDiv className='p-2 md:p-2 border-2 border-white small'>
          <FontAwesomeIcon icon={faPhone} className="text-white text-xs md:text-sm" />
        </StoneShapeDiv> */}
        <a href="tel:+1234567890" className="text-white small cursor-pointer text-xs md:text-sm ml-2 whitespace-nowrap" >+91 7528884302</a>
      </div>

     
      {/* <div className="flex items-center"> */}
        {/* <StoneShapeDiv className='p-1 md:p-2 border-2 border-white small'>
          <FontAwesomeIcon icon={faMapMarkerAlt} className="text-white small text-xs md:text-sm" />
        </StoneShapeDiv> */}
        {/* <p className='text-white text-xs md:text-sm ml-2'>Jalandhar, Punjab</p> */}
      {/* </div> */}

    </div>
    
  );
};

export default ContactBar;
