import "./App.css";
import ContactBar from "./bars/contactbar/contactbar";
import Footer from "./layouts/Footer/Footer";

import AppRoutes from "./routes/AppRoutes";
import "./animate.css";
import { BrowserRouter as Router } from "react-router-dom";
function App() {
  return (
    <div className="App bg-customWhite ">
      <Router>
        <div className="min-h-screen flex flex-col">
        
       
          <AppRoutes className="flex-grow" />

          <div className="mt-auto">
   
            <Footer/>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
