import React from "react";

export const InfoCard = ({ data }) => {
  const skills = data?.skills || [];

  return (
    <div className="shadow-md bg-custom  rounded-lg p-4 m-2 w-full transform transition-transform duration-300 hover:scale-110">
      <div className="mt-4 flex justify-start flex-col w-full ">
   
        <h2 className="text-xl font-bold text-start text-customdarkBlue">
          {data?.role || data?.degree||""}
        </h2>

        <div className="flex flex-col  justify-start  items-start">
          <div className="flex flex-row gap-2 justify-center items-center">
            <img
              src={data?.img || "default-image-url"}
              alt="Profile"
              className="w-5 h-5 object-cover"
            />
            <p className="text-gray-600">
              {data?.company || data?.school || ""}
            </p>
          </div>

          <p className="text-gray-400 text-sm">
            {data?.date || ""}
          </p>
        </div>

       {data.desc && <p className="mt-2 text-customGray text-start">
          {data?.desc ||  ""}
        </p> }

        <div className="mt-4 text-start flex flex-row flex-wrap gap-2 ">
          {skills.length > 0 ? (
            skills.map((skill, index) => (
              <div key={index} className="bg-customdarkBlue text-customBlue rounded-md p-2 mb-2">
                {skill}
              </div>
            ))
          ) : (
            <div  className="bg-customdarkBlue text-customBlue rounded-md p-2 mb-2">
            {data?.grade }
             </div>
          )}
        </div>
      </div>
      
    </div>
  );
};
