import React, { useState, useCallback } from 'react';
import AutoResizeTextarea from '../../components/AutoResizeTextArea/AutoResizeTextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import img1 from "../../assets/images/3d-illustration-asian-woman-red-jacket-gray-background.jpg";
import img2 from "../../assets/images/3d-illustration-cute-cartoon-boy-with-backpack-his-back.jpg";
import img3 from "../../assets/images/3d-illustration-teenager-with-funny-face-glasses.jpg";
import img4 from "../../assets/images/3d-render-little-boy-with-eyeglasses-blue-shirt.jpg";
import img5 from "../../assets/images/view-3d-woman.jpg";
import img6 from "../../assets/images/3d-illustration-cute-cartoon-girl-with-brown-hair-eyeglasses.jpg";
import img7 from "../../assets/images/3d-illustration-cute-little-girl-eyeglasses.jpg";
import img8 from "../../assets/images/3d-render-little-boy-with-cap-jacket-gray-background.jpg";
import img9 from "../../assets/images/3d-rendering-cartoon-like-person-showing-thumbs-up.jpg";
import img10 from "../../assets/images/portrait-3d-female-doctor.jpg";
import img11 from "../../assets/images/portrait-3d-male-doctor.jpg";
import img12 from "../../assets/images/young-business-woman-with-glasses-white-towel-her-head-3d-rendering.jpg";
import img13 from "../../assets/images/portrait-young-woman-wearing-glasses-3d-rendering.jpg";
import img14 from "../../assets/images/portrait-boy-blue-cap-glasses-3d-rendering.jpg";
import { ToastContainer, toast } from 'react-toastify';

const TestimonialModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    position: '',
    email: '',
    message: '',
    project: '',
    avatar: img1,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const avatars = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  const handleChangeText = useCallback((event, field) => {
    const value = event;
    if (value.length <= maxChars) { 
      setFormData((prevDetails) => ({
        ...prevDetails,
        [field]: value,
      }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
  }, [setFormData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.position) newErrors.position = "Position is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (formData.message.length <= 10) {
      newErrors.message = "The message must be more than 10 characters long.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; 
    }

    setErrors({});
    setLoading(true);

    setTimeout(() => {
      onSubmit(formData);
      setFormData({ name: "", position: "", email: "", message: "", project: "", avatar: img1 });
      setLoading(false);
      onClose();
    }, 2000);
  };

  const handleAvatarSelect = (avatar) => {
    setFormData({ ...formData, avatar });
  };

  const maxChars = 200; 
  const charCount = formData.message.length; 
  const progress = (charCount / maxChars) * 100; 

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[999999]">
      <ToastContainer />
      <div className="bg-opacity-20 absolute inset-0 bg-customeLightBlue" />
      <div className="bg-customWhite rounded-lg shadow-lg relative z-10 w-11/12 md:w-1/2">
        <div className='bg-customdarkBlue text-customWhite justify-between flex flex-row w-full p-6 rounded-t-lg'>
          <h2 className="text-2xl text-left font-semibold">Submit Testimonial</h2>
          <FontAwesomeIcon icon={faTimes} size='2xl' onClick={onClose} className='cursor-pointer' />
        </div>
        <form className='p-6 h-96 overflow hide-scrollbar'>
          <div className="mb-4">
            <label className="block text-customdarkBlue text-left capitalise font-semibold">Select Avatar</label>
            <div className="flex flex-wrap gap-2">
              {avatars.map((avatar, index) => (
                <img
                  key={index}
                  src={avatar}
                  alt={`Avatar ${index + 1}`}
                  onClick={() => handleAvatarSelect(avatar)}
                  className={`cursor-pointer border-2 rounded ${formData.avatar === avatar ? 'border-blue-500' : 'border-transparent'}`}
                  style={{ width: '50px', height: '50px' }}
                />
              ))}
            </div>
          </div>
          <div className='flex flex-col md:flex-row gap-4 w-full'>
            <div className="mb-4 w-full md:w-6/12">
              <label className="block text-customdarkBlue text-left capitalise font-semibold" htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder='Enter Your Name'
                className="border border-gray-300 rounded p-2 w-full"
                required
              />
              {errors.name && <div className="text-red-500 text-left">{errors.name}</div>}
            </div>
            <div className="mb-4 w-full md:w-6/12">
              <label className="block text-customdarkBlue text-left capitalise font-semibold" htmlFor="position">Position</label>
              <input
                type="text"
                name="position"
                id="position"
                value={formData.position}
                onChange={handleChange}
                placeholder='Enter Your Position'
                className="border border-gray-300 rounded p-2 w-full"
                required
              />
              {errors.position && <div className="text-red-500 text-left">{errors.position}</div>}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-customdarkBlue text-left capitalise font-semibold" htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              placeholder='Enter Your Email'
              className="border border-gray-300 rounded p-2 w-full"
              required
            />
            {errors.email && <div className="text-red-500 text-left">{errors.email}</div>}
          </div>
          <div className="mb-4">
            <label className="block text-customdarkBlue text-left capitalise font-semibold" htmlFor="project">Project</label>
            <input
              type="text"
              name="project"
              id="project"
              value={formData.project}
              onChange={handleChange}
              placeholder='Enter Project Name'
              className="border border-gray-300 rounded p-2 w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-customdarkBlue text-left capitalise font-semibold" htmlFor="message">Message</label>
            <AutoResizeTextarea
              name="message"
              id="message"
              value={formData.message}
              onChange={(value) => handleChangeText(value, "message")}
              className="border border-gray-300 rounded p-2 w-full"
              rows="4"
              required
              placeholder={"Enter Your Testimonial"}
            />
            {errors.message && <div className="text-red-500 text-left">{errors.message}</div>}
            <div className="mt-2">
              <div className="flex justify-between items-center">
                <span>{charCount}/{maxChars} characters</span>
                <div className="relative w-full h-2 bg-custom rounded mx-2">
                  <div
                    className="absolute top-0 left-0 h-2 bg-customdarkBlue rounded"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="flex justify-end m-4">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-customdarkBlue text-customWhite font-semibold py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialModal;
